import { render, staticRenderFns } from "./operate.vue?vue&type=template&id=ecd87c9c&scoped=true"
import script from "./operate.vue?vue&type=script&lang=js"
export * from "./operate.vue?vue&type=script&lang=js"
import style0 from "./operate.vue?vue&type=style&index=0&id=ecd87c9c&prod&lang=css"
import style1 from "./operate.vue?vue&type=style&index=1&id=ecd87c9c&prod&lang=scss&scoped=true"
import style2 from "./operate.vue?vue&type=style&index=2&id=ecd87c9c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecd87c9c",
  null
  
)

export default component.exports